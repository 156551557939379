// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/Footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/Header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modal-js": () => import("./../../../src/pages/Modal.js" /* webpackChunkName: "component---src-pages-modal-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

